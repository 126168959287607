<template>
    <form class="p-4" @submit.prevent="onSubmit">
        <b-container>
            <b-row>
                <b-col>
                    <div class="text-center">
                        <router-link to="home">
                            <img style="height: 4.25rem; width: 20rem" src="SmartRacks.svg" class="rounded mb-2">
                        </router-link>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="text-uppercase title text-dark">Forgot Password</div>
                    </div>
                    <div class="my-2">Please input your e-mail or phone, <b>new</b> password will be sent to you.</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <dx-form
                            ref="form"
                            :form-data.sync="formData"
                            :show-validation-summary="true"
                    >
                        <dx-item
                                data-field="Login"
                                editor-type="dxTextBox"
                                :editor-options="{ placeholder: 'Enter E-Mail or Phone', width: '100%', height: 35 }"
                        >
                            <dx-required-rule message="E-Mail or Phone is required"/>
                            <dx-label :visible="false"/>
                        </dx-item>

                        <dx-button-item>
                            <dx-button-options
                                    width="100%"
                                    type="default"
                                    template="requestPassword"
                                    :use-submit-behavior="true"
                                    :height="35"
                            >
                            </dx-button-options>
                        </dx-button-item>

                        <dx-button-item>
                            <dx-button-options
                                    width="100%"
                                    type="normal"
                                    template="back"
                                    :use-submit-behavior="false"
                                    :height="35"
                                    :on-click="onBackToLogin"
                            >
                            </dx-button-options>
                        </dx-button-item>

                        <template #requestPassword>
                            <div>
                                <span class="dx-button-text">
                                  <span>Reset Password</span>
                                </span>
                            </div>
                        </template>

                        <template #back>
                            <div>
                                <span class="dx-button-text">
                                  <span>Back to Login</span>
                                </span>
                            </div>
                        </template>
                    </dx-form>
                    <div v-if="error" class="dx-field pt-2">
                        <div class="link text-center mt-2 font-weight-bold text-danger">
                            {{ error.response.data.message }}
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </form>
</template>

<script>
/* eslint-disable */

import DxForm, {
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxCustomRule,
    DxRequiredRule,
    DxStringLengthRule,
} from 'devextreme-vue/form';

import DxButton from "devextreme-vue/button";
import {DxTextBox, DxButton as DxTextBoxButton} from 'devextreme-vue/text-box';
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
    name: 'forgot-password-view',

    components: {
        DxForm,
        DxItem,
        DxLabel,
        DxButtonItem,
        DxButtonOptions,
        DxRequiredRule,
        DxCustomRule,
        DxTextBoxButton,
        DxTextBox
    },

    data() {
        return {
            error: null,
            title: this.$appInfo.title,
            company: this.$appInfo.company,
            formData: {
                Login: "",
            },

            validating: false,
        }
    },

    mounted() {
        this.formData.Login = this.User ? (this.User.Email ? this.User.Email : this.User.Phone) : ""
    },

    methods: {
        async onSubmit(e) {
            e.preventDefault()

            await Promise.all([this.RequestPassword()])
        },

        async onBackToLogin(e) {
            await Promise.all([this.BackToLogin()])
        },

        async RequestPassword() {
            this.validating = true

            await this.$store.dispatch('user/forgot_password', this.formData)
                    .then((r) => {
                        this.validating = false

                        this.$toast.success(r.data.message)
                        this.$router.push('login')
                    })
                    .catch(e => {
                        this.error = e
                        this.validating = false
                        this.$toast.error(e.response.data.message)
                    }).finally(() => {
                        this.validating = false
                    })
        },

        async BackToLogin(e) {
            this.validating = false

            await this.$router.push('/login')
        },
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        }
    }
}
</script>

<style lang="scss">
@import "../../themes/generated/variables.base";
@import "../../fm-styles";

.header {
    text-align: center;
    margin-bottom: 20px;

    .icon {
        color: $FuelMastersAccentRed;
        font-size: 10rem;
        margin: 0
    }

    .title {
        color: $FuelMastersBlue;
        font-size: 22px;
        margin: 0;
    }
}

</style>
